import { createAction } from 'redux-act';

export const MediaStreamModelAction = {
    getMediaStream: createAction(
        'MODELS / MEDIA_STREAM / getMediaStream',
        (mediaStream: MediaStream) => ({ mediaStream }),
    ),
    toggleFacingMode: createAction('MODELS / MEDIA_STREAM / toggleFacingMode'),
    setFacingModeToggleAbility: createAction(
        'MODELS / MEDIA_STREAM / setFacingModeToggleAbility',
        (isFacingModeToggleAble: boolean) => ({ isFacingModeToggleAble }),
    ),
};
