import { createReducer } from 'redux-act';
import { NotificationViewAction } from './actions';

export type Illustration = {
    src: string;
    alt: string;
    width: string;
    height: string;
}

export type Button = {
    text: string;
    callback?: () => void;
}

export type Notification = {
    illustration: Illustration;
    heading: string;
    text: string;
    button?: Button;
    timeout?: number;
}

export type NotificationViewState = Notification | null;

const initialState: NotificationViewState = null;

export const notificationViewReducer = createReducer({}, initialState)
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    .on(NotificationViewAction.showNotification, (state, { notification }) => notification)
    .on(NotificationViewAction.hideNotification, () => null);
