import {
    call,
    put,
    select,
} from 'redux-saga/effects';
import { MediaStreamModelAction } from './actions';
import { mediaStreamModelSelector } from './selectors';

export function* checkFacingModeToggleAbility() {
    const mediaDevices: MediaDeviceInfo[] = yield call(() => (
        navigator.mediaDevices.enumerateDevices()
            .catch((reason) => {
                throw new Error(reason);
            })
    ));

    const videoInputDevices = mediaDevices.filter(({ kind }) => kind === 'videoinput');

    yield put(MediaStreamModelAction.setFacingModeToggleAbility(videoInputDevices.length > 1));
}

export function* getMediaStream() {
    const { facingMode, mediaStream }: ReturnType<typeof mediaStreamModelSelector> = yield select(mediaStreamModelSelector);

    if (mediaStream) {
        mediaStream
            .getTracks()
            .forEach((track) => track.stop());
    }

    const constraints = {
        video: { facingMode },
    };

    const newMediaStream: MediaStream = yield call(() => (
        navigator.mediaDevices.getUserMedia(constraints)
            .catch((reason) => {
                throw new Error(reason);
            })
    ));

    yield put(
        MediaStreamModelAction.getMediaStream(newMediaStream),
    );
}
