import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { CONTEXT_ROOT } from 'constants/common';
import { rootReducer } from './reducer';
import { rootSaga } from './sagas';

export const configureStore = (initialState = {}) => {
    const history = createBrowserHistory({ basename: CONTEXT_ROOT });
    const sagaMiddleware = createSagaMiddleware();
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
        rootReducer(history),
        initialState,
        composeEnhancers(
            applyMiddleware(
                routerMiddleware(history),
                sagaMiddleware,
            ),
        ),
    );

    sagaMiddleware.run(rootSaga);

    return { store, history };
};
