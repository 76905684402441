import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { AppViewAction } from 'store/views/app/actions';
import { videosModelSelector } from 'store/models/videos/selectors';
import { currentPathSelector } from 'store/selectors';
import { appViewSelector } from 'store/views/app/selectors';
import { App as Component } from './app';

const mapStateToProps = createSelector(
    appViewSelector,
    videosModelSelector,
    currentPathSelector,
    (appViewState, videoModelState, currentPath) => ({
        ...appViewState,
        currentPath,
        videosIds: videoModelState?.map(({ id }) => id) || null,
    }),
);

const mapDispatchToProps = {
    onInit: AppViewAction.init,
};

export const App = connect(mapStateToProps, mapDispatchToProps)(Component);
