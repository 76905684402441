import { createReducer } from 'redux-act';
import { AppViewAction } from './actions';

export type AppViewState = {
    isLoading: boolean;
    isError: boolean;
};

const initialState: AppViewState = {
    isLoading: false,
    isError: false,
};

export const appViewReducer = createReducer({}, initialState)
    .on(AppViewAction.initStart, (state) => ({ ...state, isLoading: true }))
    .on(AppViewAction.initSuccess, (state) => ({ ...state, isLoading: false, isError: false }))
    .on(AppViewAction.initFailure, (state) => ({ ...state, isLoading: false, isError: true }));
