import { combineReducers } from 'redux';
import { mediaStreamModelReducer, MediaStreamModelState } from './media-stream/reducer';
import { videosModelReducer, VideosModelState } from './videos/reducer';

export type ModelsState = {
    videos: VideosModelState;
    mediaStream: MediaStreamModelState;
}

export const modelsReducer = combineReducers<ModelsState>({
    videos: videosModelReducer,
    mediaStream: mediaStreamModelReducer,
});
