import { createReducer } from 'redux-act';
import { VideosModelAction } from './actions';

export type Video = {
    id: string;
}

export type VideosModelState = Video[] | null;

const initialState: VideosModelState = null;

export const videosModelReducer = createReducer({}, initialState)
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    .on(VideosModelAction.getVideos, (state, { videos }) => videos);
