import { all, fork } from 'redux-saga/effects';
import { modelsSaga } from './models/sagas';
import { viewsSaga } from './views/sagas';
import { utilsSaga } from './utils/sagas';

export function* rootSaga() {
    yield all([
        fork(modelsSaga),
        fork(viewsSaga),
        fork(utilsSaga),
    ]);
}
