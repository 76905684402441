import { connect } from 'react-redux';
import { NotificationViewAction } from 'store/views/notification/actions';
import { notificationViewSelector } from 'store/views/notification/selectors';
import { ApplicationState } from 'store/reducer';
import { Notification as Component } from './notification';

const mapStateToProps = (state: ApplicationState) => {
    const notificationViewState = notificationViewSelector(state);

    return {
        illustration: notificationViewState?.illustration || null,
        heading: notificationViewState?.heading || null,
        text: notificationViewState?.text || null,
        button: notificationViewState?.button || null,
        timeout: notificationViewState?.timeout || null,
    };
};

export const mapDispatchToProps = {
    onClose: NotificationViewAction.hideNotification,
};

export const Notification = connect(mapStateToProps, mapDispatchToProps)(Component);
