import { createReducer } from 'redux-act';
import { MediaStreamModelAction } from './actions';

export enum VideoConstraintsFacingMode {
    ENVIRONMENT = 'environment',
    USER = 'user',
}

export type MediaStreamModelState = {
    facingMode: VideoConstraintsFacingMode;
    isFacingModeToggleAble: boolean;
    mediaStream: MediaStream | null;
};

const initialState: MediaStreamModelState = {
    facingMode: VideoConstraintsFacingMode.ENVIRONMENT,
    isFacingModeToggleAble: false,
    mediaStream: null,
};

export const mediaStreamModelReducer = createReducer({}, initialState)
    .on(MediaStreamModelAction.getMediaStream, (state, { mediaStream }) => ({ ...state, mediaStream }))
    .on(MediaStreamModelAction.toggleFacingMode, (state) => ({
        ...state,
        mediaStream: null,
        facingMode: state.facingMode === VideoConstraintsFacingMode.ENVIRONMENT
            ? VideoConstraintsFacingMode.USER
            : VideoConstraintsFacingMode.ENVIRONMENT,
    }))
    .on(MediaStreamModelAction.setFacingModeToggleAbility, (state, { isFacingModeToggleAble }) => ({ ...state, isFacingModeToggleAble }));
