import React, { FC } from 'react';
import { createCn } from 'bem-react-classname';
import './video.scss';

type Props = {
    videoId: string;
}

const videoCn = createCn('video');

export const Video: FC<Props> = ({ videoId }) => (
    <iframe className={ videoCn('player') } src={ `https://www.youtube.com/embed/${videoId}?controls=1` } title="YouTube Video Player" allowFullScreen={ true } frameBorder="0" />
);
