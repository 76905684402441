import { put, takeEvery, select } from 'redux-saga/effects';
import { NotificationViewAction } from 'store/views/notification/actions';
import { getVideos } from 'store/models/videos/sagas';
import { checkFacingModeToggleAbility, getMediaStream } from 'store/models/media-stream/sagas';
import { currentPathSelector } from 'store/selectors';
import { Path } from 'constants/path';
import { ErrorCode } from 'constants/error-code';
import { ERROR_ILLUSTRATION, NO_CAMERA_ILLUSTRATION, WELCOME_ILLUSTRATION } from 'constants/illustrations';
import { store } from 'index';
import { AppViewAction } from './actions';

export function checkMediaDevicesApiSupport() {
    if (
        !navigator.mediaDevices ||
        !navigator.mediaDevices.getUserMedia
    ) {
        throw new Error(ErrorCode.NO_MEDIADEVICES_API);
    }
}

function* appViewInit() {
    try {
        const currentPath: ReturnType<typeof currentPathSelector> = yield select(currentPathSelector);
        const isRootPath = currentPath === Path.ROOT;

        yield put(AppViewAction.initStart());

        yield checkMediaDevicesApiSupport();
        yield getVideos();
        yield getMediaStream();
        yield checkFacingModeToggleAbility();

        yield put(AppViewAction.initSuccess());

        if (isRootPath) {
            yield put(NotificationViewAction.showNotification({
                illustration: WELCOME_ILLUSTRATION,
                heading: 'Добро пожаловать',
                text: 'Для того, чтобы посмотреть видео наведите камеру вашего устройства на QR-код.',
                button: {
                    text: 'Начать',
                },
            }));
        }
    } catch (error) {
        yield put(AppViewAction.initFailure());

        switch (error.message) {
        case ErrorCode.NO_MEDIADEVICES_API:
            yield put(NotificationViewAction.showNotification({
                illustration: ERROR_ILLUSTRATION,
                heading: 'MediaDevices API',
                text: 'MediaDevices API, необходимый для работы приложения, недоступен.',
                button: {
                    text: 'Перезагрузить',
                    callback: () => store.dispatch(AppViewAction.init()),
                },
            }));
            break;

        case ErrorCode.NOT_ALLOWED_MEDIA_DEVICE_ACCESS:
            yield put(NotificationViewAction.showNotification({
                illustration: NO_CAMERA_ILLUSTRATION,
                heading: 'Нет доступа',
                text: 'Приложению необходимо получить доступ к вашей камере.',
                button: {
                    text: 'Перезагрузить',
                    callback: () => store.dispatch(AppViewAction.init()),
                },
            }));
            break;

        case ErrorCode.INCOMPATIBLE_DATA:
            yield put(NotificationViewAction.showNotification({
                illustration: ERROR_ILLUSTRATION,
                heading: 'Несовместимые данные',
                text: 'Сервер предоставил несовместимые с приложением данные.',
                button: {
                    text: 'Попробовать еще раз',
                    callback: () => store.dispatch(AppViewAction.init()),
                },
            }));
            break;

        case ErrorCode.FAILED_TO_FETCH:
            yield put(NotificationViewAction.showNotification({
                illustration: ERROR_ILLUSTRATION,
                heading: 'Проблемы с соединением',
                text: 'Не удалось соединиться с сервером.',
                button: {
                    text: 'Попробовать еще раз',
                    callback: () => store.dispatch(AppViewAction.init()),
                },
            }));
            break;

        default:
            yield put(NotificationViewAction.showNotification({
                illustration: ERROR_ILLUSTRATION,
                heading: 'Непредвиденная ошибка',
                text: 'Произошла непредвиденная ошибка.',
                button: {
                    text: 'Перезагрузить',
                    callback: () => store.dispatch(AppViewAction.init()),
                },
            }));
        }
    }
}

export function* appViewSaga() {
    yield takeEvery(AppViewAction.init, appViewInit);
}
