import React, { FC, useCallback, useEffect } from 'react';
import { createCn } from 'bem-react-classname';
import { Button } from 'views/button';
import { Illustration, Button as ButtonProp } from 'store/views/notification/reducer';
import './notification.scss';

type Props = {
    illustration: Illustration | null;
    heading: string | null;
    text: string | null;
    button: ButtonProp | null;
    timeout: number | null;
    onClose: () => void;
    className?: string;
}

const notificationCn = createCn('notification');

export const Notification: FC<Props> = ({
    illustration, heading, text, button, timeout, onClose, className,
}) => {
    const handleButtonClick = useCallback(() => {
        if (button?.callback) {
            button.callback();
        }

        onClose();
    }, [button, onClose]);

    useEffect(() => {
        if (timeout) {
            setTimeout(() => {
                onClose();
            }, timeout);
        }
    }, [timeout, onClose]);

    if (!(illustration && heading && text && button)) {
        return null;
    }

    return (
        <div className={ `${notificationCn()} ${className || ''}`.trim() }>
            <div className={ notificationCn('window') }>
                <img className={ notificationCn('illustration') } src={ illustration.src } alt={ illustration.alt } width={ illustration.width } height={ illustration.height } />
                <b className={ notificationCn('heading') }>{ heading }</b>
                <p className={ notificationCn('text') }>{ text }</p>
                { button && <Button onClick={ handleButtonClick } className={ notificationCn('button') }>{ button.text }</Button> }
            </div>
        </div>
    );
};
