import { combineReducers } from 'redux';
import { notificationViewReducer, NotificationViewState } from './notification/reducer';
import { appViewReducer, AppViewState } from './app/reducer';
import { cameraViewReducer, CameraViewState } from './camera/reducer';

export type ViewsState = {
    app: AppViewState;
    notification: NotificationViewState;
    camera: CameraViewState;
};

export const viewsReducer = combineReducers<ViewsState>({
    app: appViewReducer,
    notification: notificationViewReducer,
    camera: cameraViewReducer,
});
