import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { mediaStreamModelSelector } from 'store/models/media-stream/selectors';
import { UtilsAction } from 'store/utils/actions';
import { CameraViewAction } from 'store/views/camera/actions';
import { Camera as Component } from './camera';

const mapStateToProps = createSelector(
    mediaStreamModelSelector,
    ({ mediaStream, isFacingModeToggleAble }) => ({
        mediaStream,
        isFacingModeToggleAble,
    }),
);

const mapDispatchToProps = {
    onInit: UtilsAction.decodeFromStream,
    onChangeCameraButtonClick: CameraViewAction.toggleFacingMode,
};

export const Camera = connect(mapStateToProps, mapDispatchToProps)(Component);
