import { createReducer } from 'redux-act';
import { CameraViewAction } from './actions';

export type CameraViewState = {
    isLoading: boolean;
    isError: boolean;
};

const initialState: CameraViewState = {
    isLoading: false,
    isError: false,
};

export const cameraViewReducer = createReducer({}, initialState)
    .on(CameraViewAction.toggleFacingModeStart, (state) => ({ ...state, isLoading: true }))
    .on(CameraViewAction.toggleFacingModeSuccess, (state) => ({ ...state, isLoading: false, isError: false }))
    .on(CameraViewAction.toggleFacingModeFailure, (state) => ({ ...state, isLoading: false, isError: true }));
