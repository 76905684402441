import React, { FC, useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { createCn } from 'bem-react-classname';
import { Path } from 'constants/path';
import { Camera } from './camera';
import { Notification } from './notification';
import { Video } from './video';
import { ReactComponent as Spin } from './spin.svg';
import logo from './logo.png';
import './app.scss';

type Props = {
    videosIds: string[] | null;
    isLoading: boolean;
    isError: boolean;
    onInit: () => void;
}

const appCn = createCn('app');

export const App: FC<Props> = ({
    videosIds, isLoading, isError, onInit,
}) => {
    useEffect(() => {
        onInit();
    }, [onInit]);

    return (
        <div className={ appCn() }>
            { (isLoading && (
                <React.Fragment>
                    <img className={ appCn('logo') } src={ logo } alt="Жестовый код" width="220" height="220" />
                    <Spin />
                </React.Fragment>
            )) || (isError && (
                null
            )) || (videosIds && (
                <React.Fragment>
                    <Switch>
                        { videosIds.map((videosId) => (
                            <Route key={ videosId } path={ `${Path.VIDEOS}/${videosId}` } exact={ true }>
                                <Video videoId={ videosId } />
                            </Route>
                        )) }
                        <Route component={ Camera } />
                    </Switch>
                </React.Fragment>
            )) }
            <Notification />
        </div>
    );
};
