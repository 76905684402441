import { all, fork } from 'redux-saga/effects';
import { appViewSaga } from './app/sagas';
import { cameraViewSaga } from './camera/sagas';

export function* viewsSaga() {
    yield all([
        fork(appViewSaga),
        fork(cameraViewSaga),
    ]);
}
