import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { App } from './views';
import { configureStore } from './store';
import * as serviceWorker from './service-worker';
import './style.scss';

export const { store, history } = configureStore();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={ store }>
            <ConnectedRouter history={ history }>
                <App />
            </ConnectedRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

/* TODO
    В том случае, когда приложению недоступен MediaDevices API, вылетает модалка, жмешь понятно, и тебе предстает труп
    то же самое и с ошибкой получения доступа к камере, там можно нажать на смену камеры, потом на Попробовать еще раз и будет труп
 */
