import {
    takeLatest,
    select,
    call,
    put,
    cancelled,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { decoder } from 'utils/decoder';
import { Path } from 'constants/path';
import { mediaStreamModelSelector } from 'store/models/media-stream/selectors';
import { videosModelSelector } from 'store/models/videos/selectors';
import { UtilsAction } from './actions';

export function* decodeFromStream() {
    try {
        const { mediaStream }: ReturnType<typeof mediaStreamModelSelector> = yield select(mediaStreamModelSelector);
        const videos: ReturnType<typeof videosModelSelector> = yield select(videosModelSelector);
        const videoIds = videos?.map(({ id }) => id) || [];

        if (!mediaStream) {
            throw new Error('No media stream to decode.');
        }

        const result = yield call(() => new Promise((resolve) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            decoder.decodeFromStream(mediaStream, undefined, (decodeResult) => {
                const text = decodeResult?.getText();

                if (videoIds.includes(text)) {
                    resolve(text);
                }
            });
        }));

        if (!result) {
            throw new Error('Decode from media stream failure.');
        }

        yield put(
            push(`${Path.VIDEOS}/${result}`),
        );
    } catch (error) {
        yield cancelled();
    }
}

export function* utilsSaga() {
    yield takeLatest(UtilsAction.decodeFromStream, decodeFromStream);
}
