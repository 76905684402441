import { call, put } from 'redux-saga/effects';
import { fetcher } from 'utils/fetcher';
import { ErrorCode } from 'constants/error-code';
import { VideosModelAction } from './actions';
import { Video } from './reducer';

export function* getVideos() {
    const videos: Video[] = yield call(fetcher.getVideos);

    if (
        !videos ||
        !Array.isArray(videos) ||
        !videos.every((video: Video) => typeof video.id === 'string')
    ) {
        throw new Error(ErrorCode.INCOMPATIBLE_DATA);
    }

    yield put(
        VideosModelAction.getVideos(videos),
    );
}
