import React, { FC, useRef, useEffect } from 'react';
import { createCn } from 'bem-react-classname';
import { IconButton } from './icon-button';
import { ReactComponent as ChangeCameraIcon } from './change-camera-icon.svg';
import './camera.scss';

type Props = {
    mediaStream: MediaStream | null;
    isFacingModeToggleAble: boolean;
    onInit: () => void;
    onChangeCameraButtonClick: () => void;
}

const cameraCn = createCn('camera');

export const Camera: FC<Props> = ({
    mediaStream, isFacingModeToggleAble, onInit, onChangeCameraButtonClick,
}) => {
    const cameraElement = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        onInit();
    }, [onInit]);

    useEffect(() => {
        if (!cameraElement.current) {
            return;
        }

        if ('srcObject' in cameraElement.current) {
            cameraElement.current.srcObject = mediaStream;
        } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            cameraElement.current.src = URL.createObjectURL(mediaStream);
        }
    }, [cameraElement, mediaStream]);

    return (
        <div className={ cameraCn() }>
            <div className={ cameraCn('controls') }>
                { isFacingModeToggleAble && (
                    <IconButton onClick={ onChangeCameraButtonClick }>
                        <ChangeCameraIcon width="50" height="50" style={ { display: 'block' } } role="img" aria-label="Переключить камеру" />
                    </IconButton>
                ) }
            </div>
            { mediaStream && (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <video ref={ cameraElement } className={ cameraCn('video') } autoPlay={ true } />
            ) }
        </div>
    );
};
