import React, { FC } from 'react';
import { createCn } from 'bem-react-classname';
import './icon-button.scss';

type Props = {
    className?: string;
    onClick?: () => void;
}

const iconButtonCn = createCn('icon-button');

export const IconButton: FC<Props> = ({ className, ...restProps }) => (
    <button className={ className ? `${iconButtonCn()} ${className}` : iconButtonCn() } type="button" { ...restProps } />
);
