import { SERVER_URL } from '../constants/common';

export type Fetcher = {
    [key: string]: () => Promise<unknown>;
}

const HTTPMethod = {
    GET: 'GET',
};

const HTTPResponseStatus = {
    OK: 200,
    MULTIPLE_CHOICE: 300,
};

const checkStatus = (response: Response) => {
    const { status, statusText } = response;

    if (status >= HTTPResponseStatus.OK && status < HTTPResponseStatus.MULTIPLE_CHOICE) {
        return response;
    }

    throw new Error(`${status}: ${statusText}`);
};

export const fetcher: Fetcher = {
    getVideos: () => fetch(`${SERVER_URL}/videos/`, {
        method: HTTPMethod.GET,
        headers: new Headers(),
    })
        .then(checkStatus)
        .then((response) => response.json())
        .catch((error) => {
            throw error;
        }),
};
