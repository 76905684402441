import React, { FC } from 'react';
import { createCn } from 'bem-react-classname';
import './button.scss';

type Props = {
    className?: string;
    onClick?: () => void;
}

const buttonCn = createCn('button');

export const Button: FC<Props> = ({ className, ...restProps }) => (
    <button className={ `${buttonCn()} ${className || ''}`.trim() } type="button" { ...restProps } />
);
