import { Illustration } from 'store/views/notification/reducer';
import welcomeIllustration from 'images/welcome.svg';
import noCameraIllustration from 'images/no-camera.svg';
import errorIllustration from 'images/error.svg';

export const WELCOME_ILLUSTRATION: Illustration = {
    src: welcomeIllustration,
    alt: 'Добро пожаловать',
    width: '100%',
    height: 'auto',
};

export const NO_CAMERA_ILLUSTRATION: Illustration = {
    src: noCameraIllustration,
    alt: 'Нет камеры',
    width: '50%',
    height: 'auto',
};

export const ERROR_ILLUSTRATION: Illustration = {
    src: errorIllustration,
    alt: 'Ошибка',
    width: '50%',
    height: 'auto',
};
