import { put, takeEvery } from 'redux-saga/effects';
import { getMediaStream } from 'store/models/media-stream/sagas';
import { MediaStreamModelAction } from 'store/models/media-stream/actions';
import { CameraViewAction } from './actions';

function* toggleFacingMode() {
    try {
        yield put(CameraViewAction.toggleFacingModeStart());

        yield put(MediaStreamModelAction.toggleFacingMode());
        yield getMediaStream();

        yield put(CameraViewAction.toggleFacingModeSuccess());
    } catch (error) {
        yield put(CameraViewAction.toggleFacingModeFailure());
    }
}

export function* cameraViewSaga() {
    yield takeEvery(CameraViewAction.toggleFacingMode, toggleFacingMode);
}
