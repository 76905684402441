import { createAction } from 'redux-act';
import { Notification } from './reducer';

export const NotificationViewAction = {
    showNotification: createAction(
        'VIEWS / NOTIFICATION / showNotification',
        (notification: Notification) => ({ notification }),
    ),
    hideNotification: createAction('VIEWS / NOTIFICATION / hideNotification'),
};
