import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { modelsReducer, ModelsState } from './models/reducer';
import { viewsReducer, ViewsState } from './views/reducer';

export type ApplicationState = {
    router: RouterState;
    models: ModelsState;
    views: ViewsState;
};

export const rootReducer = (history: History) => combineReducers<ApplicationState>({
    router: connectRouter(history),
    models: modelsReducer,
    views: viewsReducer,
});
